import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Loading from "../components/Loading";
import './StockRecommendationChart.css';
import StockSignalsDashboard from './StockSignalsDashboard';
import MultiStockAnalysis from './MultiStockAnalysis';
import API_BASE_URL from './const.js';
import StockTradeLogs from "./StockTradeLogs";

const StockRecommendationChart = () => {
    const [stockName, setStockName] = useState('NVDA');
    const [buyDate, setBuyDate] = useState('');
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [analysisResult, setAnalysisResult] = useState(null);
    const [activeTab, setActiveTab] = useState('chart');

    const { getAccessTokenSilently } = useAuth0();
    const history = useHistory(); // Initialize useHistory

    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        setBuyDate(today);
    }, []);

    const isDateValid = (date) => {
        const selectedDate = new Date(date);
        const minDate = new Date('2020-01-01');
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // const dayOfWeek = selectedDate.getUTCDay();
        const isWeekend = false;
        // const isWeekend = dayOfWeek === 5 || dayOfWeek === 6;

        if (isWeekend) {
            setError('Please select a weekday.');
            return false;
        } else if (selectedDate < minDate) {
            setError('Date must be after 2020-01-01.');
            return false;
        } else if (selectedDate > today) {
            setError('Date cannot be in the future.');
            return false;
        } else {
            setError('');
            return true;
        }
    };

    const isValidStock = async (stock) => {
        const url = `${API_BASE_URL}/api/validate_stock?stock_name=${stock}`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            return data.valid;
        } catch (error) {
            console.error('Error validating stock symbol:', error);
            return false;
        }
    };

    const handleAnalyze = async () => {
        if (!buyDate) {
            setError('Please select a buy date.');
            return;
        }

        if (!isDateValid(buyDate)) {
            return;
        }

        const isValid = await isValidStock(stockName);
        if (!isValid) {
            setError('Invalid stock symbol. Please enter a valid stock symbol, e.g., NVDA for Nvidia, AAPL for Apple Inc.');
            return;
        }

        setLoading(true);
        setError('');

        const token = await getAccessTokenSilently();
        try {
            const response = await fetch(
                `${API_BASE_URL}/api/get_stock_analysis?stock_name=${stockName}&buy_date=${buyDate}`,
                { headers: { 'Authorization': `Bearer ${token}` } }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail);
            }
            const apiData = await response.json();
            console.log(apiData)
            const formattedData = apiData.data.map(item => ({
                ...item,
                close: Number(item.close.toFixed(2)),
                profit: item.profit !== null ? Number(item.profit.toFixed(2)) : null
            }));
            setData(formattedData);
            setAnalysisResult({
                Stock_Date: apiData.Stock_Date,
                Signal: apiData.Signal,
                BuyScore: apiData.BuyScore,
                Analysis: apiData.Analysis
            });
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const getRecommendationText = (buyOrSell) => {
        if (buyOrSell === 1) return 'Buy';
        if (buyOrSell === 0) return 'Sell';
        return 'Neutral';
    };

    const getRecommendationColor = (buyOrSell) => {
        if (buyOrSell === 1) return 'green';
        if (buyOrSell === 0) return 'red';
        return 'grey';
    };

    const getYDomain = (data) => {
        if (data.length === 0) return [0, 'auto'];
        const prices = data.map(item => item.close);
        const min = Math.min(...prices);
        const max = Math.max(...prices);
        return [min * 0.95, max * 1.05]; // Adjust the range slightly to add padding
    };

    return (
        <div className="stock-chart">
            <div className="tab-container">
                <button
                  className={`tab-button ${activeTab === 'chart' ? 'active' : ''}`}
                  onClick={() => setActiveTab('chart')}
                >
                    Stock Analysis
                </button>
                <button
                  className={`tab-button ${activeTab === 'multi' ? 'active' : ''}`}
                  onClick={() => setActiveTab('multi')}
                >
                    Multi Stock Analysis
                </button>
                <button
                  className={`tab-button ${activeTab === 'dashboard' ? 'active' : ''}`}
                  onClick={() => setActiveTab('dashboard')}
                >
                    Last Month Stock Signals
                </button>
                <button
                  className={`tab-button ${activeTab === 'trade-logs' ? 'active' : ''}`}
                  onClick={() => setActiveTab('trade-logs')}
                >
                    AI Trade Logs
                </button>
            </div>

            {activeTab === 'chart' && (
              <div className="chart-tab">
                  <div className="input-container">
                      <input
                        type="text"
                        value={stockName}
                        onChange={(e) => setStockName(e.target.value)}
                            placeholder="Stock symbol (e.g., NVDA)"
                            className="input"
                        />
                        <input
                            type="date"
                            value={buyDate}
                            onChange={(e) => setBuyDate(e.target.value)}
                            className="input"
                        />
                        <button onClick={handleAnalyze} className="button" disabled={loading}>
                            {loading ? 'Analyzing...' : 'Analyze Stock'}
                        </button>
                    </div>
                    {error && <div className="error">{error}</div>}
                    {loading && <div className="loading">Loading, it usually takes around 15 seconds</div>}
                    {analysisResult && (
                        <div className="analysis-result" style={{ backgroundColor: getRecommendationColor(analysisResult.Signal) }}>
                            <h3>Analysis Result</h3>
                            <p>Recommendation: {getRecommendationText(analysisResult.Signal)}</p>
                            <p>Buy Score: {analysisResult.BuyScore}</p>
                            <p>Analysis:</p>
                            <p className="analysis-text">{analysisResult.Analysis}</p> {/* Display analysis with line breaks */}
                        </div>
                    )}

                    {data.length > 0 && (
                        <div className="chart-container">
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={data} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="date"
                                        tick={{ fontSize: 10 }}
                                        interval={'preserveStartEnd'}
                                        tickFormatter={(value) => value}
                                    />
                                    <YAxis
                                        yAxisId="left"
                                        tick={{ fontSize: 10 }}
                                        tickFormatter={(value) => `$${value.toFixed(2)}`}
                                        domain={getYDomain(data)}
                                    />
                                    <YAxis
                                        yAxisId="right"
                                        orientation="right"
                                        tick={{ fontSize: 10 }}
                                        tickFormatter={(value) => `${value}%`}
                                    />
                                    <Tooltip />
                                    <Legend wrapperStyle={{ fontSize: 10, paddingTop: 10 }} />
                                    <Line type="monotone" dataKey="close" stroke="#8884d8" yAxisId="left" name="Stock Price" dot={false} />
                                    <Line type="monotone" dataKey="profit" stroke="#82ca9d" yAxisId="right" name="Profit (%)" dot={false} />
                                    <ReferenceLine
                                        x={buyDate}
                                        stroke="green"
                                        label={{ value: "Buy Date", fontSize: 10, position: 'top' }}
                                        yAxisId="left" 
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    )}
                    <button
                        onClick={() => history.push('/about')}
                        className="button"
                        style={{
                            backgroundColor: 'transparent',
                            color: '#555',
                            border: 'none',
                            padding: '5px',
                            fontSize: '14px',
                            cursor: 'pointer',
                            textDecoration: 'underline'
                        }}
                    >
                        Learn More About This Signal
                    </button>
                </div>
            )}

            {activeTab === 'dashboard' && (
                <div className="dashboard-tab">
                    <StockSignalsDashboard />
                </div>
            )}

            {activeTab === 'multi' && (
                <div className="dashboard-tab">
                    <MultiStockAnalysis />
                </div>
            )}

            {activeTab === 'trade-logs' && (
              <div className="dashboard-tab">
                  <StockTradeLogs />
              </div>
            )}
        </div>
    );
};

export default withAuthenticationRequired(StockRecommendationChart, {
    onRedirecting: () => <Loading />,
});
